<!--
 * @Author: your name
 * @Date: 2021-07-28 14:25:47
 * @LastEditTime: 2021-07-28 14:26:34
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /iot-product-tool/src/pages/factory/stock/checkDevcie.vue
-->
<template>
    <div class="page-check-device">
        <div class="page-body">
            <div class="title">请扫描或手动输入设备外包装上的条形码</div>
            <div class="input-box">
                <Field v-model="code" @input="inputChange" @focus="inputFocus = true" @blur="inputFocus = false" label="Z" placeholder="请输入条形码内容" maxlength="11"/>
                <Button text="扫码条形码" @click="scan" :round="true" />
            </div>
        </div>
        <div :class="{'page-footer': true, 'focus': inputFocus}">
            <div class="footer-tip">*出库时需校验发货设备是否为30批次的探头。如为30批次的探头，不得出库</div>
            <Button text="出库校验" @click="checkDevcie" size="large" :disabled="!isPass" :round="true"/>
        </div>

        <Popup v-model="showModal" @close="code = ''">
            <div class="modal-box">
                <div class="modal-header">
                    <img :src="ossHost + (isEnable ? 'img/adjust_done.png' : 'img/adjust_falied.png')" alt="">
                </div>
                <div class="modal-body">
                    <div class="model-tip" v-if="isEnable">该设备未配备30批次的探头，可以出库</div>
                    <div class="model-tip" v-else>该设备配备了30批次的探头，不得出库！</div>
                    <div class="model-item" v-if="item.uniqueId">设备编号：<span>{{item.uniqueId}}</span></div>
                    <div class="model-item" v-if="item.code">条形码：<span>{{item.code}}</span></div>
                </div>
                <div class="modal-footer">
                    <Button :class="{'success': isEnable}" :text="isEnable ? '关闭' : '我知道了'" size="small" @click="showModal = false" :round="true"/>
                </div>
            </div>
        </Popup>
    </div>
</template>

<script>
import { Button, Field, Popup } from "vant";
// import { getYMD } from '@/utils/time'
export default {
    name: "CheckDevice",
    components: {
        Button,
        Field,
        Popup
    },
    data() {
        const { ossHost } = this.$config.base;
        return {
            ossHost,
            loading: false,
            checkList: {},
            code: '',
            showModal: false,
            isEnable: false,
            item: {},
            inputFocus: false
        };
    },
    created() {
        const vm = this;
        // const { USER_NAME } = vm.$config.keys;
        // let name = vm.$localStorage.getItem(USER_NAME);
        const uuid = vm.$localStorage.getItem('uuid') || vm.$route.query.uuid;
        const fType = vm.$strTool.filterFactoryType(uuid);
        const checkDeviceCache = vm.$localStorage.getItem('checkDeviceCache') || ''
        if (fType != 'lansong') {
            vm.$router.replace({
                path: "404"
            });
            return
        }
        if (checkDeviceCache) {
            vm.checkList = checkDeviceCache
        } else {
            vm.loadDeviceJSON();
        }
        vm.weixinInit()
    },
    computed: {
        isPass() {
            return this.code.length == 11
        }
    },
    methods: {
        inputChange(e) {
            console.log(e)
            // Toast(JSON.stringify(e))
        },
        loadDeviceJSON() {
            const vm = this;
            vm.loading = true
            vm.$http({
                type: "get",
                url: `${vm.ossHost}file/probe30.json`,
                except: true
            }).then(res => {
                vm.$localStorage.setItem('checkDeviceCache', res)
                vm.checkDeviceCache = JSON.parse(res)
            });
        },
        weixinInit() {
            const vm = this;
            vm.$http({
                type: "get",
                url: `https://m.yudada.com/jssdk/configuration?url=${
                    location.href
                }`
            }).then(res => {
                const { appId, nonceStr, signature, timestamp } = res.data;
                window.wx.config({
                    debug: false,
                    appId,
                    timestamp,
                    nonceStr,
                    signature,
                    jsApiList: ["scanQRCode"]
                });
            });
        },

        scan() {
            const vm = this
            window.wx.scanQRCode({
                needResult: 1,
                scanType: ["barCode"],
                success: function(res) {
                    const result = res.resultStr.replace('Z', '').split(',')[1];
                    vm.code = result
                }
            });
        },

        checkDevcie() {
            const vm = this
            const item = vm.checkList.list.filter(li => li.code == `Z${vm.code}`)[0] || {}
            if (item && item.code) {
                vm.item = item
                vm.isEnable = false
            } else {
                vm.item = {}
                vm.isEnable = true
            }
            vm.showModal = true
        }
    }
};
</script>

<style lang="less">
@import "@/less/base.less";
.page-check-device {
    width: 100%;
    height: 100%;
    background: @bgColor2;
    position: relative;
    overflow-y: auto;
    color: @black;
    padding-top: 60px;

    .page-body {
        padding: 0 10px;
        text-align: center;
        .title {
            padding: 0 50px;
            font-size: 0.24rem;
            font-weight: bold;
            color: @black;
            line-height: 1.5;
        }
        .input-box {
            margin-top: 80px;
            font-weight: bold;
            .van-field {
                background: @bgColor2;
                color: @black;
                line-height: 1.2;
                padding-left: 0;
                &:after {
                    display: none;
                }
                .van-field__label {
                    color: @black;
                    width: 30px;
                    font-size: 0.36rem;
                }
                .van-field__value {
                    border-bottom: 1px solid @borderColor3;
                    input {
                        font-size: 0.36rem;
                        letter-spacing: 3px;
                        &::-webkit-input-placeholder { /* WebKit browsers */
                            font-size: 0.3rem;
                        }
                    }
                }
            }

            button {
                width: 50%;
                margin: 20px;
                font-size: 0.2rem;
                letter-spacing: 2px;
                color: @themeColor;
                border-color: @themeColor;
            }
        }
    }

    .page-footer {
        // position: absolute;
        // z-index: 1;
        // left: 0;
        // right: 0;
        // bottom: 0;
        // padding-bottom: 20px;
        padding-top: 60px;
        text-align: center;
        transition: all 1s ease;
        &.focus {
            bottom: -200px;
        }

        .footer-tip {
            padding: 15px;
            background: @bgColor4;
            color: @bgColor5;
            font-size: 0.14rem;
            margin-bottom: 10px;
            line-height: 1.6;
        }

        .van-button {
            width: 65%;
            background: @themeColor;
            color: @blank;
            height: 65px;
            font-size: 0.2rem;
        }
    }
    .van-popup {
        border-radius: 10px;
        padding: 15px;
        text-align: center;
        width: 80%;
        .modal-header {
            img {
                width: 100px;
                display: block;
                margin: 0 auto 20px;
            }
        }
        .modal-body {
            .model-tip {
                font-size: 0.2rem;
                color: @black;
                font-weight: bold;
                margin-bottom: 20px;
            }
            .model-item {
                font-size: 0.2rem;
                margin-bottom: 10px;
                color: @textColor12;
                text-align: left;
                span {
                    letter-spacing: 2px;
                    font-weight: bold;
                    color: @black;
                }
            }
        }
        .modal-footer {
            button {
                height: 50px;
                width: 45%;
                color: @textColor2;
                border-color: @textColor2;
                letter-spacing: 2px;
                font-weight: bold;
                font-size: 0.2rem;
                &.success {
                    color: @themeColor;
                    border-color: @themeColor;
                }
            }
        }
    }
}
</style>

